import { differenceInMonths, parseISO } from 'date-fns';

export function calculateRetirementAge(birthDate, gender, isFemaleCadre, contributionYears) {
  const birth = parseISO(birthDate);
  const today = new Date();
  const ageInMonths = differenceInMonths(today, birth);
  const ageInYears = ageInMonths / 12;

  let statutoryRetirementAge;
  let originalRetirementAge;
  let delayStartDate = new Date('2025-01-01');

  // 确定原法定退休年龄
  if (gender === '男') {
    originalRetirementAge = 60;
  } else {
    if (isFemaleCadre) {
      originalRetirementAge = 55;
    } else {
      originalRetirementAge = 50;
    }
  }

  // 根据第一条规则计算延迟退休年龄
  if (today >= delayStartDate) {
    // 计算延迟的月份数
    let delayMonths = 0;

    if (gender === '男' || originalRetirementAge === 55) {
      // 每四个月延迟一个月
      const monthsSinceStart = differenceInMonths(today, delayStartDate);
      delayMonths = Math.floor(monthsSinceStart / 4);
      delayMonths = Math.min(delayMonths, (63 - originalRetirementAge) * 12);
    } else {
      // 每两个月延迟一个月
      const monthsSinceStart = differenceInMonths(today, delayStartDate);
      delayMonths = Math.floor(monthsSinceStart / 2);
      delayMonths = Math.min(delayMonths, (55 - originalRetirementAge) * 12);
    }

    statutoryRetirementAge = originalRetirementAge + delayMonths / 12;
  } else {
    statutoryRetirementAge = originalRetirementAge;
  }

  // 根据第三条规则，考虑弹性提前或延迟退休
  let flexibleRetirementAge = statutoryRetirementAge;

  // 可以在此添加更多逻辑，根据缴费年限和用户选择，调整退休年龄

  return flexibleRetirementAge.toFixed(1);
}