import React, { useState } from 'react';
import { calculateRetirementAge } from './utils';

function App() {
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('男');
  const [isFemaleCadre, setIsFemaleCadre] = useState(false);
  const [contributionYears, setContributionYears] = useState('');
  const [retirementAge, setRetirementAge] = useState(null);

  const handleCalculate = () => {
    const age = calculateRetirementAge(birthDate, gender, isFemaleCadre, contributionYears);
    setRetirementAge(age);
  };

  return (
    <div className="App">
      <h1>延迟退休计算器</h1>
      <label>
        出生日期：
        <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
      </label>
      <label>
        性别：
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="男">男</option>
          <option value="女">女</option>
        </select>
      </label>
      {gender === '女' && (
        <label>
          是否为女干部或女管理人员：
          <input
            type="checkbox"
            checked={isFemaleCadre}
            onChange={(e) => setIsFemaleCadre(e.target.checked)}
          />
        </label>
      )}
      <label>
        缴费年限（年）：
        <input
          type="number"
          value={contributionYears}
          onChange={(e) => setContributionYears(e.target.value)}
        />
      </label>
      <button onClick={handleCalculate}>计算退休年龄</button>
      {retirementAge && (
        <div className="result">
          <h2>您的法定退休年龄是：{retirementAge} 岁</h2>
        </div>
      )}
    </div>
  );
}

export default App;